import React from "react";
import "./card.component.style.css";
import { useState } from "react";

const CardComp = (props) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
    setIsClicked(!isClicked);
    };

    return (
    <div className={`card ${isClicked ? 'clicked' : ''}`} onClick={handleClick}>
        <div className="card-content">
          <img src={props.cardImage} alt = {props.cardImageText}/>
          <h2>{props.cardTitle}</h2>
          <p>{props.cardContent}</p>
        </div>
    </div>
  );
};

export default CardComp;