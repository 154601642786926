import React from 'react';

const IntroComp = (props) => {
    return (
        <section>
            <div className="intro-section">
                <h1 className="intro-heading">{props.introHead}</h1>
                <h2 className="intro-subheading">{props.introSub}</h2>
            </div>
        </section>
    );
};

export default IntroComp;