import React from "react";
import "./teachto.css";
import "../../index.css";
import IntroComp from "../../components/intro/intro.component";
import "../../components/intro/intro.component.style.css";

const TeachTo = () => {
    return (
      <div className="teachto">
        <div className="teachto-intro">
          <IntroComp introHead = "TeachTo" 
          introSub = "Assistive tech for fringe teachers"/>
        </div>
        <div className="teachto-paragraph">
          <a href="https://givebackhack.com/stories/spring-rocket-fuel-round-blasts-off/">More info coming soon!</a>
        </div>
      </div>
      );
}

export default TeachTo;