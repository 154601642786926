import React from "react";
import "./givebackkids.css";
import "../../index.css";
import IntroComp from "../../components/intro/intro.component";
import "../../components/intro/intro.component.style.css";

const GiveBackKids = () => {
    return (
        <div className="givebackkids">
        <div className="givebackkids-intro">
          <IntroComp introHead = "GiveBackKids" 
          introSub = "Entrepreneurship curriculum with heart"/>
        </div>
        <div className="givebackkids-paragraph">
          <a href="https://givebackhack.notion.site/GiveBackKids-d00a04322613461eafa81c0db7b771c1">More info coming soon!</a>
        </div>
      </div>
    );
};

export default GiveBackKids;