import React from 'react';
import './index.css';
import SiteRoutes from './SiteRoutes';



const App = () => {
  return (
    <SiteRoutes />
  );
};

export default App;
