import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import TeachTo from './pages/teachto/teachto';
import GiveBackKids from './pages/givebackkids/givebackkids';

const SiteRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/teachto" element={<TeachTo />} />
          <Route path="/givebackkids" element={<GiveBackKids />} />
        </Routes>
      </Router>
    );
};

export default SiteRoutes;