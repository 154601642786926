import React from 'react';
import IntroComp from '../components/intro/intro.component';
import CardComp from '../components/cards/card.component';
import ScribbleLogo from '../assets/TeachTo_Logo_Port.png';
import GBHLogo from '../assets/GBH_Just_Logo_Color_Port.png';
import '../components/cards/card.component.style.css';          
import '../components/intro/intro.component.style.css';
import { Link } from 'react-router-dom';   

const Home = () => {
    return (
        <div className='home'>
            <div className='intro'>
                <IntroComp introHead = "Hi, I'm Owen" introSub = "Thanks for visiting!"/>
            </div>
            <div className='card-container'>
                <Link to='/teachto' style={{ textDecoration: 'none' }}>
                    <CardComp cardImage = {ScribbleLogo} cardImageText = "Many lines." 
                    cardTitle = "TeachTo"/>
                </Link>
                <Link to='/givebackkids' style={{ textDecoration: 'none' }}>
                    <CardComp cardImage = {GBHLogo} cardImageText = "Cross with rocketship." 
                    cardTitle = "GiveBackKids"/>    
                </Link>
            </div>
            
        </div>
    );
};

export default Home;